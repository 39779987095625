.square-box {
  display: block;
  position: relative;
  min-width: 5rem;
  overflow: hidden;
  background-color: $dark;

  &::before {
    content: '';
    display: block;
    padding-top: 100%;
  }
}
.center-box {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background-color: $dark;
  text-align: center;
}
.box-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  vertical-align: bottom;
}
