//
// formm-wizard.scss
//

.form-wizard-header {
    width: 100%;
    .nav-item{
        margin: 0 0.5em;
        text-align: left;
        &:first-child{
            margin-left: 0;
        }
        &:last-child{
            margin-right: 0;
        }
        .nav-link{
            min-height: 150px;
            border-radius: 4px;
            background-color:   rgba($primary, 0.2);
            border: 1px solid rgba($primary, 0.2);
            .number{
                display: inline-block;
                line-height: 30px;
                margin-right: 10px;
                width: 30px;
                border-radius: 50%;
                color: $primary;
                background-color: rgba($primary, 0.2);
                text-align: center;
            }
            &.active{
                background-color: $primary;
                color: #fff;

                .number {
                    color: $white;
                    background-color: rgba($white, 0.2);
                }
            }
        }
    }
}