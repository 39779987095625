.media-library-wrapper {
  height: 60vh;
  overflow-y: scroll;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.checkable-image-box {
  vertical-align: top;
  position: relative;
  padding: 0;
  cursor: pointer;

  &.disabled {
    pointer-events: none;
    background-color: $gray-300;
    cursor: default;

    &::before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      color: $gray-400;
      opacity: 1;
      display: block;
      margin: auto;
      width: 3.5rem;
      height: 1rem;
      background-color: transparent;
      border: none;
      z-index: 1;
    }
    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: $gray-900;
      opacity: 0.8;
      display: block;
    }
  }

  // チェックボックス型
  &::before {
    content: '';
    position: absolute;
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    top: 0.5rem;
    left: 0.5rem;
    background: $white;
    border: 1px solid $gray-300;
    border-radius: 3px;
    z-index: 1;
  }
  &.checked::after {
    content: '';
    position: absolute;
    display: block;
    width: 1rem;
    height: 1.8rem;
    opacity: 1;
    top: 0;
    left: 1rem;
    border-right: 0.4rem solid $success;
    border-bottom: 0.4rem solid $success;
    transform: rotate(45deg);
    z-index: 2;
  }
}

.delete-btn {
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;
  float: right;
  padding: 0 4px;
  opacity: 0.9;
  border-radius: 0;
  z-index: 2;

  &-danger {
    background-color: $danger;
    &:hover,
    &:active,
    &:visited,
    &:focus {
      background-color: $danger;
    }
  }
}

.tag-btn {
  position: absolute;
  top: 1.25rem;
  right: 1.2rem;
  float: right;
  padding: 0 4px;
  opacity: 0.9;
  background-color: $library-tag-button-normal;
  border-color: $library-tag-button-light;
  border-radius: 0;
  z-index: 2;
  &:hover,
  &:active,
  &:focus {
    background-color: $library-tag-button-normal;
    border-color: $library-tag-button-dark;
  }
}
.tag-item {
  background-color: $library-tag-bg;
  border-radius: 0 2px 2px 0;
  color: $library-tag-text;
  display: inline-block;
  font-size: 10px;
  line-height: 16px;
  margin: 3px 4px 3px 8px;
  padding: 0 6px 0 8px;
  position: relative;
  text-decoration: none;
  cursor: pointer;
  &:hover,
  &:active,
  &:focus {
    background-color: $main-light;
    color: $white;
    &::before {
      border-color: transparent $main-light transparent transparent;
      border-style: solid;
      border-width: 8px 8px 8px 0;
      content: '';
      height: 0;
      left: -8px;
      position: absolute;
      top: 0;
      width: 0;
    }
  }
  &::before {
    border-color: transparent $library-tag-bg transparent transparent;
    border-style: solid;
    border-width: 8px 8px 8px 0;
    content: '';
    height: 0;
    left: -8px;
    position: absolute;
    top: 0;
    width: 0;
  }
  &::after {
    border: 2px solid #fff;
    border-radius: 50%;
    content: '';
    left: -1px;
    position: absolute;
    top: 6px;
  }
}

.checkable-image-input {
  width: 1.5rem;
  height: 1.5rem;
  top: 0.5rem;
  left: 0.5rem;
  z-index: 3;
}

.canva-item-btn {
  color: $main-light;
  &:hover {
    color: $main-light;
    font-weight: bold;
  }
}
