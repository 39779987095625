.slick-dots {
  position: static !important;
}

.slick-prev,
.slick-next {
  position: absolute;
  z-index: 10;
  &:before {
    color: $primary !important;
  }
}

.slick-next {
  right: 0px !important;
}

.slick-prev {
  left: 0px !important;
}

.approval-flow-name {
  display: inline-block;
  margin: 3px;
  padding: 1px 4px;
}
