//
// popover.scss
//

.popover-header {
  margin-top: 0;
}

.popover {
  max-width: 100%;
}
