//
// custom-switch.scss
//

.custom-switch {
  .custom-control-label {
    padding-left: 1.3rem;
    line-height: 2.2;
    &::before {
      height: 1.5rem;
      width: 2.75rem;
      border-radius: 0.8rem;
    }
    &::after {
      top: calc(0.175rem + 2px);
      left: calc(-2.2rem + 2px);
      width: calc(1.5rem - 4px);
      height: calc(1.5rem - 4px);
      background-color: #adb5bd;
      border-radius: 0.8rem;
    }
  }

  .custom-control-input:checked ~ .custom-control-label {
    &::after {
      transform: translateX(1.2rem);
    }
    &::before {
      color: $success;
      border-color: $success;
      background-color: $success;
    }
  }

  .custom-control-input:disabled:checked ~ .custom-control-label {
    &::before {
      color: $gray-400;
      border-color: $gray-400;
      background-color: $gray-400;
    }
  }
}
