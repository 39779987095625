.summary-card {
  &__text {
    font-size: 11px;
    text-align: center;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__large-text {
    font-size: 28px;
    font-weight: bold;
    color: #222;
    text-align: center;
  }
  &__num {
    font-size: 16px;
    font-weight: bold;
    color: #222;
    text-align: right;
  }
}
