//
// buttons.scss
//

.btn {
  color: $white;

  .mdi {
    &:before {
      margin-top: -1px;
    }
  }
}

.btn-rounded {
  border-radius: 2em;
}

//
// light button
//
.btn-light,
.btn-white {
  color: $gray-900;
}

/**
 * custom button
 */
.btn.btn-normal {
  color: $dark;
}
.btn-normal {
  border: 1px solid $gray-300;
  background-color: $gray-100;
}

//
// Link buttons
//
// Make a button look and behave like a link
.btn-link {
  font-weight: $font-weight-normal;
  color: $link-color;
  background-color: transparent;
  @include hover {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
    background-color: transparent;
    border-color: transparent;
  }
  &:focus,
  &.focus {
    text-decoration: $link-hover-decoration;
    border-color: transparent;
    box-shadow: none;
  }
  &:disabled,
  &.disabled {
    color: $btn-link-disabled-color;
    pointer-events: none;
  } // No need for an active state here
}
//
// Alternate buttons
//
@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    @include button-outline-variant($value);
  }
}

//
// Button labels
//

.btn-label {
  margin: -0.55rem 0.9rem -0.55rem -0.9rem;
  padding: 0.6rem 0.9rem;
  background-color: rgba($gray-900, 0.1);
}

.btn-label-right {
  margin: -0.55rem -0.9rem -0.55rem 0.9rem;
  padding: 0.6rem 0.9rem;
  background-color: rgba($gray-900, 0.1);
}

//
// Button Extra Small Size
//

.btn-xs {
  padding: 0.2rem 0.6rem;
  font-size: 0.75rem;
  border-radius: 0.15rem;
}
//
// Calendar color Picker Button
//

.btn-calendar-color-picker {
  width: 50px;
  height: 30px;
  &:hover {
    opacity: unset !important;
  }
}

.fc .fc-button-primary {
  background: #000a71 !important;
}

// btn bordered

@mixin button-bordered-variant($bg) {
  background-color: $bg;
  color: $white !important;
  border-bottom: 2px solid darken($bg, 7%);
}

@each $color, $value in $theme-colors {
  .btn-bordered-#{$color} {
    @include button-bordered-variant($value);

    &:focus,
    &.focus {
      box-shadow: $btn-box-shadow, 0 0 0 $btn-focus-width rgba($value, 0.5);
    }
  }
}

// file upload

.fileupload {
  overflow: hidden;
  position: relative;
  input.upload {
    cursor: pointer;
    filter: alpha(opacity=0);
    font-size: 20px;
    margin: 0;
    opacity: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
}

#title-back-btn {
  &:hover {
    background: #eee;
  }
}
