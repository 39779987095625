@import 'variables';
@import 'custom/components/library';
@import 'custom/components/media';
@import 'custom/components/dropdown-button';

/*** common ***/
// for position sticky
body,
#wrapper,
.content-page {
  overflow: visible !important;
}
.content-page {
  padding: 0 0 90px 0 !important;
}
@media (max-width: 767.98px) {
  .content-page {
    padding: 0 0 !important;
  }
}
.password-reveal {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
}
.navbar-custom {
  z-index: 1003 !important;
  top: 0;
}
.page-title-box {
  background-color: #fff;
  box-shadow: 0 1px 1px rgba(50, 58, 70, 0.1);
  margin: 0 -12px !important;
  z-index: 1000 !important;
  justify-content: flex-start;
  position: relative;
}
// row & col
.container-padding-reset {
  padding: 0;
}
.row-margin-reset {
  margin-right: 0;
  margin-left: 0;
}
.col-padding-reset {
  padding-top: 0;
  padding-bottom: 0;
}
.main-text {
  white-space: pre-wrap !important;
}
.over-flow-wrap {
  word-wrap: break-word;
  white-space: normal;
}
.over-flow-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  &:hover {
    color: $main-light !important;
    text-decoration: underline;
  }
}
.btn:disabled {
  pointer-events: none;
}
.list-unstyled {
  list-style: none;
}
.sns-thumbnail {
  border-radius: 50%;
  padding: 0;
  margin: 0.5em;
}
.link-instagram {
  font-weight: bold;
  color: $black;
  &:hover {
    text-decoration: underline;
  }
}

@for $i from 0 through 5 {
  .opacity-#{$i} {
    opacity: 0.2 * $i;
  }
}

.p-thumbnail {
  &.sm {
    padding: 0.1rem;
  }
  &.md {
    padding: 0.2rem;
  }
  &.lg {
    padding: 0.3rem;
  }
}
// 連携切れSNSのサムネイル用
.avatar-xs {
}
.overlay {
  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    margin: 0.375rem;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 50%;
  }
  &-xs::after {
    height: 1.5rem;
    width: 1.5rem;
  }
  &-sm::after {
    height: 2.25rem;
    width: 2.25rem;
  }
  //&-md::after {
  //  height: 2.25rem;
  //  width: 2.25rem;
  //}
  //&-lg::after {
  //  height: 2.25rem;
  //  width: 2.25rem;
  //}
}
.nowrap-text {
  white-space: nowrap;
}

/*** container ***/
.container {
  max-width: 100% !important;
}

/*** mdi icon ***/
.mdi {
  &.md {
    font-size: medium;
  }
  &.lg {
    font-size: large;
  }
  &.xl {
    font-size: x-large;
  }
  &.justify-position {
    top: 0.1rem !important;
    position: relative !important;
  }
}
.mdi-help-circle:before {
  color: #aaaaaa;
  cursor: pointer;
}

/*** detail ***/
.border-3 {
  border-width: 3px !important;
}
.cancelBtn {
  color: #666666;
}
a:not([href]) {
  color: #3bafda;
  cursor: pointer;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #eeeeee;
}

/**
 * dropdown button
 */
.dropdown {
  &-thumbnail {
    @extend .sns-thumbnail;
    width: 1.5em;
    height: 1.5em;
  }
  &-menu-container {
    max-height: 50vh !important;
    overflow-y: auto !important;
    width: max-content !important;
  }
  &-download-btn {
    cursor: pointer;
  }
  &-sticky {
    position: sticky;
    top: 0;
    background-color: $white;
    z-index: 1000;
  }
}
.dropdown-menu.show {
  z-index: 1000 !important;
}
/**
 * form
 */
.form {
  &-area-post-edit {
    padding-right: 10px !important;
    overflow-y: scroll;
    height: 850px;
  }
  &-field-title {
    margin: 0 0 5px 0;
    font-size: 0.9rem;
    font-weight: 600;
    line-height: 1.5;
    color: $secondary;
  }
  &-container {
    &-outer {
      border-radius: 10px;
      border: 1px solid $secondary;
      padding: 10px;
    }
  }
  &-align-right {
    text-align: right;
  }
}

/**
 * アコーディオン
 */
.accordion-card {
  &-header {
    padding: 0.5rem 1.5rem;
    &-secondary {
      background-color: $gray-600;
      color: #ffffff;
      font-weight: 700;
      text-align: center;
      cursor: pointer;
    }
    &-danger {
      background-color: $danger;
      color: #ffffff;
      font-weight: 700;
      text-align: center;
      cursor: pointer;
    }
  }
  &-body {
    padding: 1rem;
  }
}

/**
 * tooltip
 */
$tooltip-bg-color: $gray-800;
.tooltip {
  z-index: $popup-z-index !important;
}
.gray-tip.tooltip-inner {
  background-color: $tooltip-bg-color;
}
@each $directionText, $direction in $directions {
  .gray-tip.tooltip-inner .bs-tooltip-#{$directionText} .arrow::before {
    border-#{$directionText}-color: $tooltip-bg-color !important;
  }
  .bs-tooltip-auto[x-placement^='#{$directionText}'] .arrow::before {
    border-#{$directionText}-color: $tooltip-bg-color !important;
  }
}

/**
 * dropdown
 */
.scrollable-dropdown {
  height: auto;
  max-height: 100vh;
  overflow-x: hidden;
}
.brand-dropdown {
  max-width: 250px;
  width: fit-content !important;
  overflow-y: auto !important;
  max-height: 50vh !important;
}

.brand-dropdown .brand-filter {
  position: sticky;
  top: 0px;
  z-index: 10;
  background: #fff;
}

.dropdown-menu-left {
  right: auto !important;
  left: 0 !important;
}

.badge {
  &-post-reply-label {
    width: 100%;
    padding: 5px 0;
    font-size: 0.8rem;
    margin-bottom: 0.3rem;
  }
}

.char-abbreviation {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  // max-width: 100% // 指定場所ごとに指定
}

.brand-dropdown .brand-displayName {
  max-width: 50%;
}

.brand-dropdown .brand-displayName-noSlash {
  max-width: 100%;
}

/**
 * mention reply
 */
.mention {
  &-text {
    font-size: 0.8rem;
    font-weight: 400;
    color: #000000;
    margin: 1rem auto;
  }
}

/**
 * calendar todoList
 */
.calendar {
  &-datepicker {
    background-color: transparent;
    font-weight: 600;
    font-size: 1.2rem;
    color: $gray-800;
    width: 11rem;
    border: none;
  }
  // row items
  &-datetime {
    width: 6rem;
    margin: 10px 0;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
    color: #343a40;
    font-size: 0.9375rem;
    line-height: 1.1;
  }
  &-name {
    width: 5rem;
    display: flex;
  }
  &-title {
    width: 25vw;
    display: flex;
  }
  &-badge {
    margin: 0 0.5rem;
    width: 7rem;
  }

  // navigation
  &-week {
    display: flex;
    flex-direction: row;
    outline: none;
  }
  &-day-cell {
    flex-grow: 1;
    transition: background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    color: #000000;
    border-bottom: 1px solid $gray-500;
    &.active {
      color: #dd4b39;
      border-bottom: 2px solid #dd4b39;
      margin-bottom: -1px;
      border-radius: 0;
    }
    &-btn {
      display: flex;
      flex-direction: column;
      line-height: inherit;
      font-size: 16px;
      padding-top: 4px;
      padding-bottom: 8px;
      align-items: center;
      background-color: transparent;
      border: none;
      &:hover {
        background-color: #ffffff;
      }
    }
    &__weekday {
      font-weight: 400;
      font-size: 12px;
      color: $gray-400;
      height: 16px;
      line-height: 16px;
    }
    &__day {
      &--today {
        color: $danger;
      }
    }
  }
  // week selector
  &-week-selector {
    &-prev {
      padding: 0.3rem 0.6rem;
      margin-right: -1px;
      border-radius: 3px 0 0 3px;
      font-size: 0.7875rem;
    }
    &-next {
      padding: 0.3rem 0.6rem;
      border-radius: 0 3px 3px 0;
      font-size: 0.7875rem;
    }
  }
  &-task-list-wrapper {
    overflow-y: scroll;
    height: 66vh;
  }
  &-expired-post {
    color: $danger;
  }
}

.calendar-modal-card {
  height: 180px;
  line-height: 1.5;
  overflow: hidden;
  overflow-y: auto;
  white-space: pre-wrap;
  border: solid 1px #c6c8cb;
}
.calendar-usage-guide-container {
  margin-top: 0.5rem;
}
.calendar-usage-guide {
  margin-left: 0.2rem;
  width: 1rem;
  height: 1rem;
}
.calendar-usage-guide-post {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
  margin: auto 1px;
  top: 40%;
  transform: translateY(40%);
}
@media (max-width: 990px) {
  .calendar-usage-guide-container {
    margin-top: 2.5rem;
    margin-bottom: 0.8rem;
  }
}
@media (max-width: 320px) {
  .calendar-usage-guide-container {
    margin-top: 2.5rem;
    margin-bottom: 1.8rem;
  }
}
/**
 * account list
 */
.oauth {
  &-provider-icon {
    position: absolute;
    top: 16px;
    text-shadow: 1px 1px 0 rgba(255, 255, 255, 1);
    left: 27px;
  }
}

/**
 * post preview
 **/
// media thumbnail
.post-media-thumbnail {
  width: 30%;
  margin-right: 3%;
}

// preview and comment
.post-edit {
  &-row {
    //height: 850px;
  }
  &-preview {
    //height: 100%;
    background-color: #c5c5c5;
  }
  &-comment {
    //height: 100%;
    background-color: #ffffff;
  }
}
.preview-and-comment-area {
  border-radius: unset;
  border: #6c757d solid 1px;
  height: 850px;
  margin-bottom: 0;
  &.card {
    min-height: 850px;
  }
}
.preview-and-comment-area > .tab-content {
  padding: 0;
  background-color: #c5c5c5;
  height: 100%;
  overflow-y: auto;
}
.post {
  &-preview-area {
    background-color: #e9e9e9;
    border-radius: unset;
    margin: 0 auto 1rem;
    height: auto;
    padding: 1rem 0;
  }
  &-preview-export {
    position: absolute !important;
    top: 1rem;
    right: 2rem;
    z-index: 1000;
  }
  &-preview-modal {
    position: absolute !important;
    top: 4rem;
    right: 2rem;
    z-index: 1000;
  }
  &-comments-area {
    min-height: 844px;
    background-color: #ffffff;
    border-radius: unset;
  }
}
.image-thumbnail {
  &-container {
    padding: 5px 0;

    &-outer {
      border-radius: 10px;
      border: 1px solid $secondary;
      padding: 5px 1px 10px 1px;
    }
  }
  &-name {
    word-break: break-all;
  }
  &-delete-btn {
    position: absolute;
    top: 0;
    right: 12px;
    float: right;
    padding: 0 4px;
    opacity: 0.6;
    border-radius: 0;
  }
}
.comment-row {
  border: 1px solid #c6c6c6;
  border-radius: 10px;
  margin-bottom: 1rem;
  white-space: pre-wrap;
  &-self {
    background-color: #f2f2f2;
    border-radius: 10px;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
  }
  &-others {
    background-color: #ffffff;
    border-radius: 10px;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
  }
}

.comment-media {
  width: inherit;
}

.drag-and-drop {
  cursor: move;
  position: absolute;
  z-index: 1000;
}
.drag {
  z-index: 1001;
}
// video controller
.vsc-controller {
  white-space: normal;
}

/*** tabs ***/
.nav-tabs {
  border-top: 1px solid #dee2e6;
  & .nav-link {
    border: none;
    border-radius: 0 !important;
    border-right: #c5c5c5 solid 1px;
    border-bottom: #c5c5c5 solid 3px;
    background-color: #fff;
    flex-grow: 1;
    &:last-child {
      border-right: none;
    }
    &:hover {
      border-bottom: #2084a9 solid 3px;
    }
  }
  & .nav-link.active,
  & .nav-link.hover {
    color: #6c757d;
    font-weight: bold;
    border-bottom: #3bafda solid 3px;
  }
  & > .tab-item {
    z-index: $popup-z-index !important;
  }
  &.subheader {
    width: 100%;
    box-sizing: border-box;
    border-bottom: 2px solid #dee2e6 !important;
    border-radius: 0;
    & .list-group-item {
      border: none !important;
      border-radius: 0 !important;
      padding: 0;
      &.active {
        border-radius: 0 !important;
        background-color: inherit !important;
        border-bottom: 3px solid $primary !important;
      }
    }
  }
}
.tab-content {
  width: 100%;
  padding: 0 1rem;
}

/*** dropzone over ride ***/
.dzu-dropzone {
  overflow: auto !important;
  border: 2px dashed #d9d9d9 !important;
  border-radius: 6px !important;
}
.dzu-input {
  display: none;
}
.dzu-inputLabel {
  width: 100% !important;
  text-align: center !important;
  color: $blue !important;
  flex-direction: column !important;
  word-break: keep-all !important;
  cursor: pointer !important;
}
.dzu-previewContainer {
  padding: 0.5rem 1.5rem !important;
  min-height: 3rem !important;
}
//.dzu-submitButton {
//  width: 75% !important;
//  margin: auto !important;
//}
.dzu-submitButtonContainer {
  width: 75% !important;
  &:hover {
    opacity: 0.8 !important;
  }
}
.dzu-dropzone-expand {
  width: 100vw !important;
  height: 100vh !important;
  background-color: rgba(0, 0, 0, 0.8) !important;
  z-index: 2000 !important;
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
}

/*** toast ***/
.toast-error {
  position: fixed;
  margin: 20px;
  top: 0;
  right: 0;
  z-index: 99;
  &-header {
    color: #ffffff;
    background-color: #ed2643;
  }
  &.show {
    z-index: 10000;
  }
}
.toast-info {
  position: fixed;
  margin: 20px;
  top: 0;
  right: 0;
  z-index: 99;
  &-header {
    color: #ffffff;
    background-color: #37cde6;
  }
  &.show {
    z-index: 10000;
  }
}
.toast-success {
  position: fixed;
  margin: 20px;
  top: 0;
  right: 0;
  z-index: 99;
  &-header {
    color: #ffffff;
    background-color: #37cde6;
  }
  &.show {
    z-index: 10000;
  }
}

/*** modal ***/
.modal-error {
  position: relative;
  margin: auto;
  top: 30%;
  z-index: 99;
  &-header {
    color: #ffffff;
    background-color: #ed2643;
  }
  &.show {
    z-index: 10000;
  }
}
.modal-scrollable {
  max-height: calc(100vh - 210px);
  overflow-y: auto;
}

.modal-size {
  max-width: 1600px;
  width: 80%;
  margin: 1rem auto;
}

.modal-calender-body {
  height: 480px;
}

.enlarged .left-side-menu {
  z-index: 10 !important; // make sure the menu is on top
}

.insight-modal-img {
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.2);
  margin-top: 0.5rem;
}

.insight-area-parent-height {
  height: 100%;
}
.insight-area-row-height {
  height: 50%;
}

@media (max-width: 990px) {
  .insight-area-row-height {
    height: unset;
  }
  .insight-area-col-height {
    height: unset;
  }
}

/**
 * table style
 */
.table-media {
  min-width: 110px;
  max-width: 110px;
}
.table-media-info {
  overflow-x: visible;
  text-overflow: ellipsis;
}
.table-post-caption {
  //border-radius: 10px;
  //border: 1px solid #c3c3c3;
  padding: 5px 0;
  margin-bottom: 10px;
  white-space: pre-line;
}

.rdt_TableBody {
  max-height: 50vh !important;
}
.rdt_TableRow {
  // min-height: auto !important;
  flex-shrink: 0 !important;
}
.rdt_TableCell {
  white-space: nowrap !important;
  //overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.ellipsis-cell {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.dRHSWe {
  min-height: auto !important;
  flex-shrink: 0 !important;
}
.bQxkxC {
  padding-bottom: 0;
  margin-bottom: 0;
}
.filter-btn {
  z-index: 1;
}

.table-thumbnail {
  width: 66px;
  height: 66px;
  //border-radius: 50%;
  object-fit: contain;
  background-color: whitesmoke;
}
.table-caption,
.table-tags {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
.btn-link-caption {
  padding: 0;
}
.post-list-content-row {
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
  align-items: flex-start !important;
  height: 100% !important;
}

.img-card-box {
  margin: 8px !important;
  border: none !important;
  padding: 0px !important;
  text-overflow: unset !important;
  background-color: transparent !important;
}
.rdt_TableBody .img-card-box:hover {
  opacity: 0.5;
}

.rdt_TableCell .ribbon-box .ribbon:before {
  content: unset !important;
}

.rdt_TableCell .ribbon-box .ribbon-success:before {
  border-color: unset !important;
}

.rdt_TableCell .ribbon-box .ribbon.float-right:before {
  right: unset !important;
}

.media-type-ribbon {
  position: absolute !important;
  right: 0 !important;
  margin-right: -10px !important;
  margin-top: 10px !important;
  padding: 2px 6px !important;
  border-radius: 3px !important;
}

/**
 * modal style for date picker
 */
.modal-scrollable-visible {
  overflow-y: visible;
}

/**
 * reactstrap file input
 */
.form-control-file {
  position: relative;
  flex: 1 1;
  min-width: 0;
  margin-bottom: 0;
  display: block;
  width: 100%;
  height: calc(1.5em + 0.9rem + 2px);
  padding: 0.45rem 0.9rem;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: #6c757d;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-right: none;
  border-radius: 0.2rem 0 0 0.2rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  overflow: visible;
  cursor: pointer;
  box-shadow: 0 0 3px 0 rgba(154, 161, 171, 0.3);
}
/*
 * daterangepicker
 */
.daterangepicker {
  z-index: $popup-z-index !important;
  &-input {
    width: max-content !important;
    width: -moz-max-content !important;
    //width: -webkit-max-content !important;
    //width: -o-max-content !important;
    //width: -ms-max-content !important;
  }
  &:after,
  &:before {
    display: none !important;
  }
}
.field-daterange-picker {
  display: inline-block !important;

  & > .react-datepicker-wrapper {
    display: inline-block !important;
    width: 7rem !important;

    & > .form-control {
    }
  }
}

/**
 * react-datepicker
 */
/*** customize ***/
.react-datepicker {
  display: inline-flex !important;
  &__input-container-daterange {
    width: 7rem !important;
    display: inline-block !important;
  }
  &-wrapper {
    display: block !important;
  }
  &__triangle {
    border: none !important;
    &:before {
      border: none !important;
    }
  }
  &-popper {
    z-index: 3 !important;
  }
}
.switchEditText {
  padding: 0.45rem 0.9rem;
  background: #fafafa;
  border: 1px #fafafa solid;
  border-radius: 2px;
  white-space: pre-wrap;
  word-wrap: break-word;
}
.switchEditText.inValid {
  color: #7d2c38;
  background-color: #fcdde2;
  border-color: #fbcfd6;
}

.fc .fc-col-header-cell-cushion,
.fc .fc-daygrid-day-number {
  color: $gray-900;
}

.fc .fc-day-sat .fc-col-header-cell-cushion,
.fc .fc-day-sat .fc-daygrid-day-number {
  color: $fc-calendar-sat;
}

.fc .fc-day-sun .fc-col-header-cell-cushion,
.fc .fc-day-sun .fc-daygrid-day-number {
  color: $fc-calendar-sun;
}

.fc .fc-daygrid-day.fc-day.fc-day-sat,
.fc .fc-col-header-cell.fc-day.fc-day-sat {
  background: $fc-calendar-sat-light;
}

.fc .fc-daygrid-day.fc-day.fc-day-sun,
.fc .fc-col-header-cell.fc-day.fc-day-sun {
  background: $fc-calendar-sun-light;
}

.fc .fc-daygrid-event.fc-daygrid-dot-event.fc-event.fc-event-start.fc-event-end.fc-event-past {
  color: #3788d8;
}

.fc .fc-daygrid-event.fc-daygrid-dot-event.fc-event.fc-event-start.fc-event-end.fc-event-today.eventDefault {
  color: $success;
}

.fc .fc-daygrid-event.fc-daygrid-dot-event.fc-event.fc-event-start.fc-event-end.fc-event-today.eventRed {
  color: $danger;
}

.fc .fc-daygrid-event.fc-daygrid-dot-event.fc-event.fc-event-start.fc-event-end.fc-event-today.eventGray {
  color: $secondary;
}

@media (max-width: 990px) {
  .app .fc .fc-toolbar.fc-header-toolbar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0;
  }
  .app .fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk {
    margin-bottom: 1rem;
    width: 100%;
  }

  .app .fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk:nth-child(2) {
    margin-bottom: 1rem;
    text-align: center;
  }
  .app .fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk:last-child {
    margin-bottom: 0;
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  .fc .fc-view-harness {
    min-height: 80vh !important;
  }
  .app .fc .fc-scrollgrid-liquid {
    height: 80vh !important;
  }
}

@media (max-width: 576px) {
  .account-daterange {
    width: 100%;
  }
  .account-daterange .d-flex {
    width: 100%;
  }
}
.post-list-content-row .post-list-data {
  border-top: 1px solid rgba(0, 0, 0, 0.08);
  padding: 0.3rem 0;
}

.post-list-content-row .post-list-data .post-list-publishAt {
  display: flex;
}

.post-list-publishAt .post-list-publishAt-label {
  font-size: 0.8rem;
}

.post-list-publishAt .post-list-publishAt-text {
  font-size: 0.9rem;
}

.img-card-box .mng-post-img-size {
  max-height: 96px;
  object-fit: contain;
}

.card-body {
  padding: 1rem;
}

.emoji-mart-emoji {
  display: inline-flex !important;
}

.post-media-tags__clear-indicator {
  display: none !important;
}

@media (max-width: 576px) {
  .rdt_TableHeader .col-6 {
    flex: auto;
    max-width: 100%;
  }
  .rdt_TableHeader {
    padding-left: 0 !important;
    padding-right: 0 !important;
    flex-direction: column !important;
    margin-bottom: 2rem;
  }
  .rdt_TableHeader > div:first-child {
    margin-bottom: 1rem;
  }
  .mng-posts .rdt_TableHeader > div {
    flex-direction: column;
  }
  .mng-posts .rdt_TableHeader #search {
    flex: initial;
    max-width: 100%;
    margin-bottom: 1rem;
    padding: 0;
  }
  .mng-posts .rdt_TableHeader .btn {
    margin: 0 !important;
    width: 100%;
  }
  .mng-posts .rdt_TableHead {
    display: none !important;
  }
  .mng-posts .rdt_TableHeader + .col-12 > div {
    display: block !important;
  }
  .rdt_TableHeader > div:nth-child(2) {
    flex-wrap: wrap;
  }

  .rdt_TableHeader #search {
    padding: 0;
    margin-bottom: 1rem;
  }

  .mng-posts .rdt_TableRow {
    flex-direction: column !important;
    border: solid 1px #eee;
    border-radius: 5px;
    margin-top: 4px;
    margin-bottom: 4px;
  }
  .mng-posts .rdt_TableCell {
    max-width: 100% !important;
    padding-top: 8px;
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  .mng-posts .rdt_TableCell .ribbon-box {
    width: 100%;
    text-align: center;
  }
  .mng-posts .post-list-publishAt {
    margin-bottom: 1rem;
  }
  .mng-posts .post-list-publishAt,
  .post-creators {
    display: block;
    width: 100%;
  }
  .mng-posts .post-list-data {
    flex-direction: column !important;
  }
  .mng-posts .posts-title-wrapper {
    flex-direction: column;
    align-items: flex-start !important;
  }

  .mng-posts .posts-title-wrapper h3 {
    white-space: initial;
  }

  .img-card-box .mng-post-img-size {
    max-height: 200px;
    object-fit: contain;
  }

  .card-body {
    padding: 16px 0 0 0;
  }

  .rdt_TableBody {
    max-height: unset !important;
  }
  .post-list-content-row .post-list-data .post-list-publishAt {
    display: block;
  }
  .post-list-publishAt .post-list-publishAt-text {
    margin-left: 8px;
  }

  .mng-posts {
    padding-right: 0;
    padding-left: 0;
  }

  .post-list-content-row .post-list-data {
    border-top: unset;
  }
}

.rankingCard {
  counter-increment: ranking;
  margin-top: 20px;
}
.rankingCard a {
  position: relative;
  display: inline-block;
}
.rankingCard:first-child a::before {
  background: #d9b340;
}
.rankingCard:nth-child(2) a::before {
  background: #6f7b83;
}
.rankingCard a::before {
  content: counter(ranking);
  position: absolute;
  top: -4px;
  left: -8px;
  background: #222;
  color: #fff;
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  border-radius: 20px;
  font-size: 10px;
}

/*** react-select ***/
.react-select-option {
  background-color: transparent;
  color: inherit;
  cursor: default;
  display: block;
  font-size: inherit;
  padding: 8px 0 8px 20px;
  width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
  &:hover {
    background-color: #deebff;
  }
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 15px;
  color: #212529;
  text-align: left;
  background-color: #fff;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
}

.accordion-description {
  white-space: pre-wrap;
  font-size: 13px;
}

.todo-input {
  padding: 3px;
  width: 30px;
  height: 25px;
  margin-left: -5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gray-tip {
  white-space: pre-line;
}

.has-ng-words {
  background-color: rgba(255, 0, 0, 0.3);
}

.has-required-words {
  background-color: rgba(0, 217, 255, 0.3);
}

.border-tiktok {
  border: 1px solid rgb(18,18,18);
}